body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--primary-50) ;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media only screen and (min-width: 768px){
  #hero-video{
    clip-path: polygon(8% 0, 100% 0%, 100% 100%, 0 100%) !important
  }
}

@media only screen and (max-width: 992px){
  #menu-wrapper, #menu-products, #menu-languages{
    background-color: rgb(22, 40, 64) !important;
  }
}

.card-fluid .p-card-body{
  width: 100%;
}

.p-border {
  padding-bottom: 1rem;
  border-bottom: 1px solid #ace3d6;
}
