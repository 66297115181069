
.button-demo .p-button {
    /*margin-right: 0.5rem;*/
}
.button-demo .p-buttonset .p-button {
    margin-right: 0;
}
.button-demo .template .p-button i {
    line-height: 2.25rem;
}


.button-demo .template .p-button.linkedin {
    background: linear-gradient(to left, var(--blue-400) 50%, var(--blue-500) 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: background-position 0.5s ease-out;
    color: #fff;
    border-color: var(--blue-500);
}
.button-demo .template .p-button.linkedin:hover {
    background-position: left bottom;
}
.button-demo .template .p-button.linkedin i {
    background-color: var(--blue-500);
}
.button-demo .template .p-button.linkedin:focus {
    box-shadow: 0 0 0 1px var(--blue-200);
}
.button-demo .template .p-button.slack {
    background: linear-gradient(to left, var(--orange-400) 50%, var(--orange-500) 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: background-position 0.5s ease-out;
    color: #fff;
    border-color: var(--orange-500);
}


.button-demo .template .p-button.facebook {
    background: linear-gradient(to left, var(--indigo-600) 50%, var(--indigo-700) 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: background-position 0.5s ease-out;
    color: #fff;
    border-color: var(--indigo-700);
}
.button-demo .template .p-button.facebook:hover {
    background-position: left bottom;
}
.button-demo .template .p-button.facebook i {
    background-color: var(--indigo-700);
}
.button-demo .template .p-button.facebook:focus {
    box-shadow: 0 0 0 1px var(--indigo-400);
}
.button-demo .template .p-button.linkedin {
    background: linear-gradient(to left, var(--blue-400) 50%, var(--blue-500) 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: background-position 0.5s ease-out;
    color: #fff;
    border-color: var(--blue-500);
}

.button-demo .template .p-button.phone {
    background: linear-gradient(to left, var(--pink-600) 50%, var(--pink-700) 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: background-position 0.5s ease-out;
    color: #fff;
    border-color: var(--pink-700);
}
.button-demo .template .p-button.phone:hover {
    background-position: left bottom;
}
.button-demo .template .p-button.phone i {
    background-color: var(--pink-700);
}
.button-demo .template .p-button.phone:focus {
    box-shadow: 0 0 0 1px var(--pink-400);
}

/*@media screen and (max-width: 480px) {*/
/*    .button-demo .p-button {*/
/*        margin-bottom: 0.5rem;*/
/*    }*/
/*    .button-demo .p-button:not(.p-button-icon-only) {*/
/*        display: flex;*/
/*        width: 100%;*/
/*    }*/
/*    .button-demo .p-buttonset .p-button {*/
/*        margin-bottom: 0;*/
/*    }*/
/*}*/

